@import 'vars';

html {
  // default browser font-size is 16px;
  // default browser line-height is 1.2em
  //font-size: 12px;
  font-size: 62.5%; /* 62.5% of 16px = 10px */

  //@media (min-width: 320px) { /* 20*16px = 320px */
  //  font-size: 250%; /* 200% of 16px = 32px */
  //}
  ////@media (min-width: 25rem) { /* 20*16px = 320px */
  ////  font-size: 250%; /* 200% of 16px = 32px */
  ////}
  //@media (min-width: 480px) { /* 30*16px = 480px */
  //  font-size: 250%; /* 300% of 16px = 48px */
  //}
  //@media (min-width: 768px) { /* 48*16px = 768px */
  //  font-size: 300%; /* 350% of 16px = 56px */
  //}
  //@media (min-width: 992px) { /* 62*16px = 992px */
  //  font-size: 375%; /* 400% of 16px = 64px */
  //}
  //@media (min-width: 1200px) { /* 75*16px = 1200px */
  //  font-size: 450%; /* 450% of 16px = 72px */
  //}
}

@media (min-width: 320px) {
  html {
    font-size: 120%;
  }
}
@media (min-width: 480px) {
  html {
    font-size: 110%;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 100%;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 90%;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 90%;
  }
}


body {
  font-family: Quicksand, Arial, Helvetica, sans-serif;
  color: $main-content-color;
}

.load-images {
  background: url(../images/logo.png),
  url(../images/logo-carre.jpg),
  url(../images/logo-enseignement-catholique.jpg),
  url(../images/img-2.jpg),
  url(../images/slide-1.jpg),
  url(../images/travaux.jpg),
  url(../images/cambridge.png),
  url(../images/anglais.jpg),
  url(../images/actu-1.jpg),
  url(../images/apel.jpg),
  url(../images/providence.jpg),
  url(../images/on-off.png);
}

.navigation {
  //font-size: 0.19rem;

  .navbar {
    position: static;
    padding: 10px 0;

    .navbar-item {
      text-align: center;
      width: 100%;
      &:hover, &.dropdown.show {
        border-bottom: 2px solid $rollover-color;
      }
    }

    .dropdown-toggle::after {
      position: absolute;
      top: 45%;
      right: 5px;
    }

    .navbar-link {
      position: relative;
      text-decoration: none;
      &.current {
        color: $rollover-color;
      }
    }

    .navbar-text {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .navbar-brand {
    margin-right: 0;
    padding: 0 15px;
    font-size: 1px;
  }

  .navbar-toggler {
    color: $title-color;
    padding: 1em 0.75em;
    //font-size: .25rem;
  }

  position: relative;

  #quick_links {
    position: absolute;
    right: 15px;
    top: 0;
    background-color: $title-color;
    z-index: 10;

    .nav-item {
      padding: 10px;
      color: #FFF;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      //font-size: 0.16rem;

      &:hover, &:active, &.current {
        background-color: #0b8d87;
        text-decoration: none;
      }

      .fa {
        display: block;
      }
    }
  }

  & > .row {
    background-color: #FFF;

    .navbar-link {
      color: $main-nav-color;
      text-transform: uppercase;
    }
  }

  .dropdown-menu-center {
    left: 50% !important;
    right: auto !important;
    text-align: center !important;
    transform: translate(-50%, 0) !important;
  }

  .dropdown-menu {
    min-width: 10em;
    padding: 0.5em 0;
    margin: 0 0;
    font-size: 1em;
    border-radius: 0.25em;
    text-transform: uppercase;
    text-align: center;

    a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: #797979;
      padding: 1em 2em .5em;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
}

.more {
  display: inline-block;
  border: 1px $title-color solid;
  text-transform: uppercase;
  color: $title-color;
  font-family: 'Quicksand', sans-serif;
  margin: 1em;
  padding: .5em 1em;

  &:link,
  &:visited {
    text-decoration: none;
  }

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $rollover-color;
    border: 1px $rollover-color solid;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.carousel, .carousel-page {
  h1 {
    font-size: 3.5rem;
  }
}

.news {
  //font-size: .2rem;

  .news-title {
    //font-size: 1.65rem;
    font-family: "Grand Hotel", cursive;
    color: $title-color;
  }

  .news-block {
    .news-content {
      .title {
        //font-size: .26rem;
      }
    }
  }
}

.english {
  h2 {
    //font-size: .70rem;
    line-height: 1em;
  }

  p {
    line-height: 1em;
  }
}

.pre-footer {
  .travaux {
    //font-size: .20rem;

    h2 {
      //font-size: .85rem;
    }
  }

  .developpement-durable {
    padding: 10px 15px;

    h2 {
      //font-size: .85rem;
      text-transform: none;

      color: $title-color;
      font-family: "Grand Hotel", cursive;
      font-weight: 400;

      span {
        text-transform: uppercase;
        display: block;
        font-family: 'Quicksand', sans-serif;
      }
    }

    h3 {
      font-size: .40rem;
    }
  }

  .travaux, .developpement-durable {
    padding-bottom: 2em;
  }
}

.apel, .providence {
  margin-bottom: 1em;
}

.footer {
  a {
    color: $main-content-color;
  }

  .row > div {
    margin-top: .1em;
    margin-bottom: 1em;
    font-size: .6rem;

    h5 {
      font-family: 'Quicksand', sans-serif;
      font-weight: 600;
      color: $title-color;
      font-size: .85rem;
    }
  }
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin-bottom: .4em;
}

.news, .english, .pre-footer {
  .row, .news-block {
    margin-bottom: 1em;
  }
}

.breadcrumb {
  background-color: transparent;
  padding: 0.75em 1em;
  margin-bottom: 1em;
  border-radius: 0.25em;

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5em;

    &:before {
      padding-right: 0.5em;
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)

@media (min-width: 768px) {
  .navigation {
    #navbar {
      height: 100%;
    }

    .navbar {
      .navbar-item {
        text-align: center;
        height: 100%;
        width: auto;
        justify-content: center;
        flex: 1 1 auto;
      }
    }
  }
  .navigation #quick_links {
    .nav-item {
      display: block;
    }
  }
  .carousel, .carousel-page {
    position: relative;

    .carousel-inner {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../images/page-title-bg.png) no-repeat center center;
        background-size: 50%;
      }
    }

    h1 {
      position: absolute;
      top: 50%;
      width: 70%;
      left: 15%;
      margin: -1em -15px;
      text-align: center;
      color: #FFF;
    }
  }
  .home-content, .content {
    font-size: 1.5em;

    font-family: 'Open Sans', sans-serif;
    color: $main-content-color;
    font-weight: 300;

    .more {
      &:link,
      &:visited {
        text-decoration: none;
      }

      &:hover, &:active, &:focus {
        text-decoration: none;
      }
    }
  }
  .news {
    .news-title-block {
      text-align: center;
      margin: 2em 0;

      &:before {
        position: absolute;
        content: '';
        border-top: 3px $main-content-color solid;
        width: 150%;
        height: 1px;
        display: block;
        top: 50%;
        left: -25%;
        z-index: 1;
      }
    }

    .all-news-link {
      text-align: center;
      font-weight: 600;
      color: $rollover-color;
      text-transform: uppercase;
    }

    .news-title {
      position: relative;
      background-color: #fff;
      display: inline-block;
      margin: 0 auto;
      z-index: 2;
      padding: 0 20px;
      font-size: 6em;
    }

    .news-block {
      padding: 0;
      align-self: flex-end;
      background-color: #FFF;
      box-shadow: #888 0 0 1em;
      margin-left: 1.5em;
      margin-right: 1.5em;

      &:nth-child(2) {
        align-self: flex-start;
        margin-bottom: 4em;
      }

      .news-content {
        padding: 2em;

        .title {
          font-weight: 600;
          font-family: 'Quicksand', sans-serif;
          color: $title-color;
          text-transform: uppercase;
          position: relative;
          margin-bottom: 20px;

          &:after {
            content: '';
            border-bottom: 3px $main-content-color solid;
            width: 20%;
            bottom: -10px;
            left: 0;
            position: absolute;
          }
        }
      }
    }
  }
  .english {
    margin-top: 30px;
    background: url(../images/anglais.jpg) no-repeat bottom center;
    background-size: cover;
    padding: 30px 15px;

    .red {
      margin-top: -50px;
      padding: 20px;
      color: #fff;
      background-color: $rollover-color;
      text-align: center;

      h2 {
        color: #fff;
        margin-bottom: .2em;
      }

      p {
        margin-bottom: 5px;
      }
    }

    .more {
      display: block;
      margin: 10px 20px;
      color: #FFF;
      border-color: #FFF;
      font-weight: 600;

      &:hover, &:active, &:focus {
        background: #FFF;
        color: $rollover-color;
        text-decoration: none;
      }
    }
  }
  .pre-footer {
    margin: 50px auto 75px;

    .travaux {
      .img-travaux {
        width: 75%;
        height: 75%;
      }

      .block-white {
        padding: 1em 20px;
        box-shadow: #555 0 0 10px;
        position: absolute;
        width: 50%;
        bottom: 10px;
        right: 3em;
        background: #FFF;
      }

      .more {
        position: absolute;
        bottom: -10px;
        right: 3em;
        color: $main-content-color;
        border: none;
        margin-bottom: -20px;
      }
    }
  }
}
